/* Modo claro y oscuro */
:root {
    --bg-light: #FFFFFF;
    --bg-dark: #000000;
    --text-light: #333333;
    --text-dark: #f5f5f5;
    --primary-color: #FFE57F;
    --secondary-color: #FFB74D;
    --accent-color: #FFD166;
}

body {
    background-color: var(--bg-light);
    color: var(--text-light);
    font-family: "Inter", sans-serif;
    transition: background 0.3s, color 0.3s;
}

.dark-mode {
    background-color: var(--bg-dark);
    color: var(--text-dark);
}

/* Contenedor principal */
.ticket-container {
    max-width: 600px;
    margin: 120px auto;
    padding: 24px;
    background-color: var(--bg-light);
    border-radius: 10px;
    text-align: center;
    transition: background 0.3s;
}

.dark .ticket-container {
    background-color: var(--bg-dark);
}

/* Títulos */
.ticket-title {
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-weight: bold;
}

.dark .ticket-title {
    color: var(--primary-color);
    font-size: 30px;
}

/* QR Code */
.qr-container {
    margin-top: 16px;
}

.qr-image {
    width: 150px;
    height: 150px;
    border-radius: 8px;
}

/* Select y email */
.email-select-container {
    margin-top: 16px;
}

.email-select {
    padding: 8px;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
}

.custom-email-container {
    margin-top: 8px;
}

.email-input {
    padding: 8px;
    width: 80%;
    border-radius: 5px;
    border: 1px solid var(--accent-color);
}

/* Botones */
.btn-primary,
.btn-secondary {
    padding: 10px 16px;
    font-size: 16px;
    margin: 8px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

.btn-primary {
    background-color: var(--secondary-color);
    color: #000000;
}

.btn-primary:hover {
    background-color: var(--accent-color);
    transform: scale(1.05);
}

.btn-secondary {
    background-color: var(--accent-color);
    color: #000000;
}

.btn-secondary:hover {
    background-color: var(--secondary-color);
    transform: scale(1.05);
}

/* Ticket para impresión */
.print-ticket {
    display: none;
}

/* Responsividad */
@media (max-width: 768px) {
    .ticket-container {
        max-width: 90%;
        margin: 180px auto;
        padding: 16px;
    }

    .ticket-title {
        font-size: 24px;
    }

    .qr-image {
        width: 120px;
        height: 120px;
    }

    .btn-primary, .btn-secondary {
        font-size: 14px;
        padding: 8px 12px;
    }

    .email-input {
        width: 100%;
    }
}
