/* Estilos para la página Home */
.home {
  padding: 30px 20px;  /* Ajusta los valores de padding según lo necesario */
  font-family: 'Arial', sans-serif;
  margin-top: 60px;
  text-align: left; /* Si quieres que el texto y los elementos estén alineados a la izquierda */
  transition: background-color 0.3s, color 0.3s;
  max-width: 100vw; /* Limita el ancho máximo al 100% del viewport */
}

html, body {
  overflow-x: hidden; /* Evita el desplazamiento horizontal */
  margin: 0; /* Elimina los márgenes predeterminados */
}



.ticket-imagemp {
  position: relative;
  top: 50%; /* O ajusta la posición según sea necesario */
  left: 35%;
  transform: translateX(-50%); /* Centra la imagen */
  z-index: 999; /* Asegura que la imagen esté por encima de otros elementos */
  width: 210%;
  height: 100px;
  margin: 1px;
}

.imagenmp {
  position: relative;
  top: 50%; /* O ajusta la posición según sea necesario */
  margin-left: 39% ;
  width: auto;
}

.ticket-imagemp2 {
  position: relative;
  top: 50%; /* O ajusta la posición según sea necesario */
  left: 35%;
  transform: translateX(-50%); /* Centra la imagen */
  z-index: 999; /* Asegura que la imagen esté por encima de otros elementos */
  width: 210%;
  height: 100px;
  margin: 1px;

}

/* Estilos del Footer */
.container .footer {
  background-color: rgb(71, 71, 71); /* Fondo oscuro */
  color: white; /* Texto blanco */
  padding: 40px 0; /* Espaciado vertical */
  text-align: center; /* Centrado del texto */
  position: relative;
  margin-top:200px;
  width: 128%;
  bottom: 0;
  margin-left: -4%
}

.footer a {
  color: white; /* Enlaces blancos */
  text-decoration: none; /* Sin subrayado en los enlaces */
  margin: 0 15px; /* Espaciado horizontal entre los enlaces */
  font-size: 14px;
}

.footer a:hover {
  text-decoration: underline; /* Subrayado al pasar el ratón */
}

/* Modal */
.modal-overlays {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black; /* Fondo oscuro semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  max-width: 200%;
  max-height: 600%;
 
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  position: relative;
  margin-top: 1%;
}

.close-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-body {
  margin-top: 20px;
}

.footer-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.footer-links a {
  margin: 0 20px;
}

.footer-links a:hover {
  text-decoration: underline;
}



/* Contenedor de texto que se desplaza */
.scrolling-text-container {
  position: relative;
  width: 100%;
  background-color: rgb(212, 207, 207); /* Fondo negro */
  overflow: hidden; /* Esconde cualquier cosa fuera del contenedor */
  height: 50px; /* Ajusta la altura según sea necesario */
}

.dark .scrolling-text-container {
  position: relative;
  width: 100%;
  background-color: rgb(129, 129, 129); /* Fondo negro */
  overflow: hidden; /* Esconde cualquier cosa fuera del contenedor */
  height: 50px; /* Ajusta la altura según sea necesario */
}

.scrollings-text {
  position: absolute;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  color: white;
  font-size: 20px; /* Tamaño de fuente */
  animation: scrollText 40s linear infinite; /* Animación que dura 10s y se repite infinitamente */
  font-size: medium;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

/* Definir la animación */
@keyframes scrollText {
  0% {
    transform: translateX(70%); /* Comienza fuera de la pantalla a la derecha */
  }
  100% {
    transform: translateX(-100%); /* Termina fuera de la pantalla a la izquierda */
  }
}

/* Estilos para los íconos de redes sociales */
.social-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-links a {
  color: inherit; /* Hereda el color de cada ícono */
  text-decoration: none; /* Elimina el subrayado */
}

.social-links:hover {
  opacity: 5; /* Efecto al pasar el mouse */
}



/* Título de la sección */
.home h2 {
  font-size: 30px;
  margin-bottom: 25px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: color 0.3s;
}



/* Estilos de la barra de búsqueda y select */
.searchs-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 220px;
  padding: 5px 5px;
  margin-top: 85px
}

.searchs-container input {
  padding: 2px 5px;
  margin: 3px;
  border: 2px solid #e9aa16;
  border-radius: 8px;
  font-size: 16px;
  background-color: #ffffff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;


}


.searchs-container select {
  padding: 5px 25px;
  margin: 3px;
  border: 1px solid #e9aa16;
  border-radius: 8px;
  font-size: 12px;
  background-color: #FFD166;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}


/* Contenedor de los botones */
.container .paginate-container {
  display: flex;
  justify-content: center;  /* Centra los botones horizontalmente */
  flex-wrap: wrap;          /* Permite que los botones se ajusten en varias filas si no hay suficiente espacio */
  gap: 1px;                /* Espacio entre los botones */
  margin-top: 20px;         /* Espacio superior */
  margin-left: 28%;
}

/* Estilos para los botones de paginación */
.container .paginate-boton {
  padding: 10px 1vw;
  font-size: 0.8rem;           /* Tamaño del texto normal */
  background-color: #FFE57F; /* Amarillo pastel brillante */
  color: #333;               /* Color oscuro para el texto */
  border: 2px solid #FFD166; /* Amarillo-naranja pastel brillante */
  border-radius: 12%;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 1%;
 
}

/* Efecto hover: al pasar el mouse sobre el botón */
.paginate-boton:hover {
  background-color: #FFB74D; /* Naranja pastel brillante */
  transform: scale(1.05);     /* Efecto de agrandar ligeramente */
}

/* Efecto active: cuando el botón está presionado */
.paginate-botonact {
  background-color: #df9d23; /* Amarillo-naranja pastel brillante */
  transform: scale(0.60);    /* Efecto de reducir el tamaño cuando se presiona */
  padding: 8px 1vw;        /* Tamaño del padding cuando está presionado */
  border-radius: 120%;
  color: black;
  
}


/* Contenedor de eventos en columnas en Home/ Cards */
.container .homecard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  justify-content: center;
  gap: 95px; /* Aumenta el gap para separar más las cards */
  /* margin: 5px; */
  margin-left: 5%;
  margin-top: 65px;
  z-index: 1;
}

.content {
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Para distribuir los elementos de arriba a abajo */
  flex-grow: 1; /* Hace que el contenido ocupe el espacio disponible y empuje el botón hacia abajo */
}

.dark .content {
  color: #ffffff
}

/* Cards color de fondo y tamaño de la misma */

/* Cards color de fondo y tamaño de la misma */
.container .cardshome {
  border-radius: 12px;
  padding: 25px;
  transition: all 0.3s ease-in-out;
  justify-content: flex-start;  /* Aseguramos que el contenido se alinee en la parte superior */
  align-items: center;
  text-align: center;
  border: 5px solid #eca624;
  overflow: hidden;
  position: relative;
  background: #ffffff;
  min-height: 300px;  /* Establecemos una altura mínima constante para las cards */
  width: 200px;
  display: flex;
  flex-direction: column;  /* Organiza los elementos en una columna */
  z-index: 1;
}

.dark .cardshome {
  background: #000000;
  /* backgroud de las cards */
}





/* Titulo y efecto del mismo en cards */
.titulo-card {
  word-wrap: break-word;  /* Rompe las palabras largas para evitar que se desborden */
  overflow-wrap: break-word;  /* Hace lo mismo que el anterior */
  hyphens: auto;  /* Agrega guiones automáticamente si es necesario */
  margin-top: -1%;  /* Añade un poco de espacio debajo del título */
}

.dark .titulo-card {
  color: #ffffff;
  /* Color Blanco */
}


/* Elementos individuales de eventos */
li {
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(22, 121, 235, 0.1);
  padding: 10px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 3px solid #000000;
  overflow: hidden;
  position: relative;
  background: #d8d785;
}

li:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

/* Títulos de los eventos */
li h3 {
  color: #080808;
  font-size: 35px;
  margin-bottom: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}


.home.dark li h3 {
  color: #bdd9ee;
}

/* Descripción de los eventos */
li p {
  font-size: 14px;
  margin: 6px 0;
  line-height: 1.4;
  text-align: justify;
}

.calendar-button {
  background-color: transparent;
  color: #e9b118;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
}

.calendar-popup {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: #fcdb8f;
  padding: 0;
  border-radius: 8px;
  color: #000000;
}

.react-calendar__tile--hasActive {
  color: rgb(15, 187, 15);
  background: #fcdb8f;
}

.react-calendar {
  background: #fcdb8f;
  border: none;
}

/* Imagen de los eventos */
.event-image-home {
  width: 250px;
  height: 110px;
  object-fit: fill;
  border-radius: 12px;
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
}

li:hover .event-image {
  transform: scale(1.02);
}

/* Botón de ver detalles */
.buttonhome {
  background-color: white;
  color: #000000;
  font-family: monospace;
  font-weight: bold;
  font-size: 18px;
  padding: 8px 16px;
  border: 3px solid #eca624;
  margin-top: 10px; /* Espacio superior para separar del contenido */
  border-radius: 0px;
  cursor: pointer;
  width: auto;
  align-self: center; /* Centra el botón horizontalmente */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Efecto cuando el título se pasa por encima */
.buttonhome:hover {
  background-color: #FFB74D;
  transform: scale(1.5);
}

.dark .buttonhome {
  color: white;
  background-color: transparent;
}

.dark .buttonhome:hover {
  color: white;
  background-color: #FFB74D;
}

.buttonhomereset {
  background-color: transparent;
  color: #f78400;
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 25%;
}

.whatsapp-link {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dark .whatsapp-link {
  color: white;
  background-color: #1D1D1D;
  /* Color del texto */
}



/* Estilo responsivo */
@media (max-width: 350px) {


  
  /* Home Section */
  .home {
    padding: 20px 15px;
  }

  .home h2 {
    font-size: 24px;
  }

  /* Contenedor de los botones */
  .paginate-container {
    display: flex;
    justify-content: center;  /* Centra los botones horizontalmente */
    flex-wrap: wrap;          /* Permite que los botones se ajusten en varias filas si no hay suficiente espacio */
    gap: 10px;                /* Espacio entre los botones */
    margin-top: 20px;         /* Espacio superior */
  }

  /* Ubicación de las cards responsivas */
  .homecard {
    grid-template-columns: repeat(2, 1fr); /* Dos tarjetas por fila */
    gap: 16px; /* Espacio entre las tarjetas */
    margin-left: 0;  /* Elimina el margen izquierdo para evitar el desbordamiento */
  }

  .cardshome {
    border-radius: 12px;
    padding: 15px;
    transition: all 0.3s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 5px solid #eca624;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    max-height: 400px;
    width: 100%;  /* Ajustamos el ancho al 100% para que se ajuste mejor en pantallas pequeñas */
    margin-left: -35px;  /* Elimina el margen izquierdo para evitar el desbordamiento */
    
  }

  li {
    padding: 12px;
  }

  li h3 {
    font-size: 18px;
  }

  li p {
    font-size: 13px;
  }

  .event-image {
    height: 120px;
  }

  .buttonhome {
    font-size: 12px;
    padding: 6px 10px;
  }

  .buttonhomereset {
    font-size: 12px;
    padding: 6px 10px;
    width: 50%;
  }

  /* Estilos de la barra de búsqueda y select */
  .searchs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;  /* Reducimos el margen inferior */
    margin-left: -30px;  /* Elimina el margen izquierdo innecesario */
    padding: 5px;
    margin-top: 20px;  /* Ajustamos para que no se desplace demasiado hacia abajo */
    width: 100%;  /* Aseguramos que ocupe todo el ancho disponible */
  }

  .searchs-container input {
    padding: 10px 20px;  /* Reducimos el padding para un ajuste más adecuado */
    margin: 3px;
    border: 2px solid #e9aa16;
    border-radius: 8px;
    font-size: 16px;
    background-color: #ffffff;
    width: 100%;  /* Ajustamos para que ocupe todo el ancho disponible */
    box-sizing: border-box;
  }

  .searchs-container select {
    padding: 5px 20px;  /* Ajustamos el padding */
    margin: 3px;
    border: 1px solid #e9aa16;
    border-radius: 8px;
    font-size: 12px;
    background-color: #FFD166;
    width: 100%;  /* Ajustamos para que ocupe todo el ancho disponible */
    box-sizing: border-box;
  }

  /* Estilo para el botón del calendario RESPONSIVE */
  .calendar-button-container {
    display: flex;
    justify-content: flex-start;
    margin: 3px;
  }

  .calendar-button {
    background-color: transparent;
    color: #e9b118;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .calendar-popup {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background-color: #fcdb8f;
    padding: 0;
    border-radius: 8px;
    color: #000000;
  }

  .react-calendar {
    background: transparent;
    border: none;
  }

  .react-calendar__navigation button {
    color: black !important;
  }

  .react-calendar__navigation__label {
    color: black !important;
  }

  .react-calendar__tile {
    color: rgb(255, 0, 0);
  }

  .react-calendar__tile--now {
    background: transparent;
  }

  .react-calendar__tile--active {
    background: transparent;
  }

  .react-calendar__tile--hasActive {
    color: green;
    background: #fcdb8f;
  }

  .reset-button {
    color: rgb(153, 20, 20);
    border: none;
    border-radius: 8px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-left: 9px;
    width: auto;
    display: inline-block;
  }

  .reset-button:hover {
    background-color: #d32f2f;
  }
  /* Estilos del Footer */
/* Footer */
.container .footer {
  background-color: rgb(71, 71, 71); /* Fondo oscuro */
  color: white; /* Texto blanco */
  padding: 40px 0; /* Espaciado vertical */
  text-align: center; /* Centrado del texto */
  position: relative;
  margin-top: 50px; /* Ajustado el margen superior */
  width: 125%; /* Aseguramos que el ancho sea 100% */
  bottom: 0;
  box-sizing: border-box;
  margin-left: -40px; /* Elimina el margen izquierdo innecesario */
}

.footer a {
  color: white; /* Enlaces blancos */
  text-decoration: none; /* Sin subrayado en los enlaces */
  margin: 0 15px; /* Espaciado horizontal entre los enlaces */
  font-size: 14px;
}

.footer a:hover {
  text-decoration: underline; /* Subrayado al pasar el ratón */
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 80%; /* Ajustado a un porcentaje más flexible */
  max-width: 400px; /* Para evitar que sea demasiado grande */
  text-align: center;
  position: relative;
}

.close-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-body {
  margin-top: 20px;
}

/* Contenedor de texto que se desplaza */
.scrolling-text-container {
  position: relative;
  width: 100%;
  background-color: rgb(212, 207, 207); /* Fondo negro */
  overflow: hidden; /* Esconde cualquier cosa fuera del contenedor */
  height: 50px; /* Ajusta la altura según sea necesario */
}

.dark .scrolling-text-container {
  position: relative;
  width: 100%;
  background-color: rgb(129, 129, 129); /* Fondo negro */
  overflow: hidden; /* Esconde cualquier cosa fuera del contenedor */
  height: 50px; /* Ajusta la altura según sea necesario */
}

.scrollings-text {
  position: absolute;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  color: white;
  font-size: 20px; /* Tamaño de fuente */
  animation: scrollText 40s linear infinite;
  font-size: medium;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

/* Definir la animación */
@keyframes scrollText {
  0% {
    transform: translateX(70%); /* Comienza fuera de la pantalla a la derecha */
  }
  100% {
    transform: translateX(-100%); /* Termina fuera de la pantalla a la izquierda */
  }
}

/* Estilos para los íconos de redes sociales */
.social-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-links a {
  color: inherit; /* Hereda el color de cada ícono */
  text-decoration: none; /* Elimina el subrayado */
}

.social-links a:hover {
  opacity: 0.8; /* Efecto al pasar el mouse */
}

/* Media Query para pantallas pequeñas */
@media (max-width: 360px) {
  
  .ticket-imagemp {
    position: relative;
    top: 50%; /* O ajusta la posición según sea necesario */
    left: 35%;
    transform: translateX(-50%); /* Centra la imagen */
    z-index: 999; /* Asegura que la imagen esté por encima de otros elementos */
    width: 210%;
    height: 100px;
    margin: 1px;
  }
  
  .imagenmp {
    position: relative;
    top: 50%; /* O ajusta la posición según sea necesario */
    margin-left: 39% ;
    width: auto;
  }
  
  .ticket-imagemp2 {
    position: relative;
    top: 50%; /* O ajusta la posición según sea necesario */
    left: 35%;
    transform: translateX(-50%); /* Centra la imagen */
    z-index: 999; /* Asegura que la imagen esté por encima de otros elementos */
    width: 210%;
    height: 100px;
    margin: 1px;
  
  }
}
}

/* Media Query para pantallas medianas */
@media (max-width: 1069px) {

  .home {
    padding: 30px 20px;
  }

  .home h2 {
    font-size: 24px;
  }

  .paginate-container {
    display: flex;
    justify-content: center;  /* Centra los botones horizontalmente */
    flex-wrap: wrap;          /* Permite que los botones se ajusten en varias filas si no hay suficiente espacio */
    gap: 10px;                /* Espacio entre los botones */
    margin-top: 20px;         /* Espacio superior */
  }

  /* UBICACION DE LAS CARDS RESPONSIVAS */
  .cardshome {
    border-radius: 12px;
    padding: 30px;
    transition: all 0.3s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 5px solid #eca624;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    width: 100%;
    
  }
  .event-image {
    height: 120px;
  }
  .buttonhome {
    font-size: 12px;
    padding: 6px 10px;
  }
  .searchs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 50%; /* Reducido el margen superior */
    padding: 5px;
  }
  .searchs-container input {
    padding: 15px 20px; /* Ajuste más flexible */
    margin: 5px;
    border: 2px solid #e9aa16;
    border-radius: 8px;
    font-size: 16px;
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
  }
  .searchs-container select {
    padding: 5px 25px;
    margin: 5px;
    border: 1px solid #e9aa16;
    border-radius: 8px;
    font-size: 12px;
    background-color: #FFD166;
    width: 100%;
    box-sizing: border-box;
  }
  .calendar-button-container {
    display: flex;
    justify-content: flex-start;
    margin: 5px;
  }
  .calendar-button {
    background-color: transparent;
    color: #e9b118;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .react-calendar {
    background: transparent;
    border: none;
  }
  .reset-button {
    color: rgb(153, 20, 20);
    border: none;
    border-radius: 8px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  .reset-button:hover {
    background-color: #d32f2f;
  }
}
/* Estilos para pantallas pequeñas (menores a 600px) */
@media (max-width: 600px) {

  /* Efecto hover: al pasar el mouse sobre el botón */
.container .paginate-boton:hover {
  background-color: #FFB74D; /* Naranja pastel brillante */
  transform: scale(1.05);     /* Efecto de agrandar ligeramente */
 
}

/* Efecto active: cuando el botón está presionado */
.container .paginate-botonact {
  background-color: #df9d23; /* Amarillo-naranja pastel brillante */
  transform: scale(0.60);    /* Efecto de reducir el tamaño cuando se presiona */
  padding: 8px 1vw;        /* Tamaño del padding cuando está presionado */
  border-radius: 120%;
  color: black; 
}
  .container .paginate-boton {
    padding: 10px 1vw;
    font-size: 0.8rem;           /* Tamaño del texto normal */
    background-color: #FFE57F; /* Amarillo pastel brillante */
    color: #333;               /* Color oscuro para el texto */
    border: 2px solid #FFD166; /* Amarillo-naranja pastel brillante */
    border-radius: 12%;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin: 1%;
    margin-left: 2%  
  }
  .ticket-imagemp {
    position: relative;
    top: 50%; /* O ajusta la posición según sea necesario */
    left: 21%;
    transform: translateX(-50%); /* Centra la imagen */
    z-index: 999; /* Asegura que la imagen esté por encima de otros elementos */
    width: 200%;
    height: 50px;
    margin: 1px;
  }
  .imagenmp {
    position: relative;
    top: 50%; /* O ajusta la posición según sea necesario */
    margin-left: 39% ;
    width: auto;
  }
  .ticket-imagemp2 {
    position: relative;
    top: 50%; /* O ajusta la posición según sea necesario */
    left: 21%;
    transform: translateX(-50%); /* Centra la imagen */
    z-index: 999; /* Asegura que la imagen esté por encima de otros elementos */
    width: 200%;
    height: 50px;
    margin: 1px;
  
  }
  .searchs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    padding: 5px 15px;
    flex-direction: column;
    margin-left: -8%
  }
  .searchs-container input {
    padding: 15px 20px;
    margin: 10px 0;
    border: 2px solid #e9aa16;
    border-radius: 8px;
    font-size: 16px;
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    margin-left: 5%;  
  }
  .searchs-container select {
    padding: 5px 25px;
    margin: 10px 0;
    border: 1px solid #e9aa16;
    border-radius: 8px;
    font-size: 12px;
    background-color: #FFD166;
    width: 100%;
    box-sizing: border-box;
    margin-left: 5%
  }
  .homecard {
    display: grid;
    grid-template-columns: 1fr; /* Una tarjeta por fila */
    gap: 16px;
    margin: 0 auto; /* Centrado de las tarjetas */
    margin-left: -10%
  }
  .cardshome {
    border-radius: 12px;
    padding: 15px;
    transition: all 0.3s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 5px solid #eca624;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    width: 90%;
    margin-left: -16%
  }
  .dark .cardshome {
    background: #000000;
  }
  .container .footer {
    background-color: rgb(71, 71, 71);
    color: white;
    padding: 20px 0;
    text-align: center;
    width: 120%;
    margin-top: 20px;
    margin-left: -27px   
  }
  .footer a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-size: 14px; 
  }
  .footer a:hover {
    text-decoration: underline;
  }
}

/* Estilos para pantallas medianas (de 361px a 1069px) */
@media (min-width: 361px) and (max-width: 1069px) {

.searchs-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      margin-top: 50%; /* Reducido el margen superior */
      padding: 5px;
    }
    .ticket-imagemp{
      width: 235%;  /* Ajustamos el tamaño a un porcentaje */
      
    }
    .ticket-imagemp2 {
      width: 235%;  /* Ajustamos el tamaño a un porcentaje */
     
    }
  .searchs-container input {
    padding: 15px 40px;
    margin: 5px;
    border: 2px solid #e9aa16;
    border-radius: 8px;
    font-size: 16px;
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    margin-top: 100%;
  }

  .searchs-container select {
    padding: 5px 25px;
    margin: 5px;
    border: 1px solid #e9aa16;
    border-radius: 8px;
    font-size: 12px;
    background-color: #FFD166;
    width: 100%;
    box-sizing: border-box;  
  }
  .homecard {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Dos tarjetas por fila */
    gap: 16px;
  }
  .cardshome {
    border-radius: 12px;
    padding: 15px;
    transition: all 0.3s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 5px solid #eca624;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    width: 100%; 
  }
  .dark .cardshome {
    background: #000000;
  }
  .footer {
    background-color: rgb(71, 71, 71);
    color: white;
    padding: 30px 0;
    text-align: center;
    margin-top: 40px;
    width: 120%;
    margin-left: -25px
  
  }
  .footer a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    font-size: 14px;  
  }
  .footer a:hover {
    text-decoration: underline;
  }
}
/* Estilos para pantallas grandes (más de 1069px) */
@media (min-width: 1069px) {
  .homecard {
    grid-template-columns: repeat(3, 1fr); /* Tres tarjetas por fila */
    gap: 16px;
  }
  .cardshome {
    border-radius: 12px;
    padding: 15px;
    transition: all 0.3s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 5px solid #eca624;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    width: 90%;
    /* margin: 0 auto; */
  }
  .dark .cardshome {
    background: #000000;
  }
  .footer {
    background-color: rgb(71, 71, 71);
    color: white;
    padding: 40px 0;
    text-align: center;
    margin-top: 100px;
    width: 190%;
    margin-left: -25px
  }
  .footer a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-size: 14px;
  }
  .footer a:hover {
    text-decoration: underline;
  }
}
