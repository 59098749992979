/* Contenedor principal */
.mis-compras-container {
  max-width: 900px;
  margin: 120px auto;
  padding: 20px;
  background-color: #FFFFFF; /* Modo Light */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.dark .mis-compras-container {
  background-color: #1D1D1D;; /* Modo Dark */
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
}

/* Títulos */
.miscompras-title {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  color: #242424;
  transition: color 0.3s;
}

.dark .miscompras-title {
  color: #ffffff;
}

/* Estilo para la lista de tickets */
.ticket-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  padding: 0;
}

/* Cada ticket ocupa una caja más pequeña */
.ticket-item {
  background:#FFB74D;
  padding: 16px;
  border-radius: 10px;
  width: 280px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ticket-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}

.dark .ticket-item {
  background:#FFE57F;
}

/* Texto de los tickets */
.ticket-item p {
  margin: 5px 0;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  color: #222;
  transition: color 0.3s;
}

.dark .ticket-item p {
  color: #000000;
}

/* Código QR */
.qr-code {
  width: 100px;
  height: 100px;
  margin-top: 12px;
}

/* Estados de carga, error y sin compras */
.loading,
.error,
.no-compras {
  text-align: center;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #000000;
  margin-top: 20px;
}

.dark .loading,
.dark .error,
.dark .no-compras {
  color: #FFFFFF;
}

/* Estilos para los textos de estado */
.event-status {
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 6px;
  margin-top: 5px;
}

.event-finalizado {
  color: #FFFFFF;
  background-color: #D32F2F;
}

.event-activo {
  color: #FFFFFF;
  background-color: #4CAF50;
}

/* Paginación */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

/* Botones de paginación */
.page-button {
  padding: 10px 18px;
  background-color: #FFB74D;
  color: #000000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  transition: background-color 0.3s, transform 0.2s ease;
}

.dark .page-button{
  background-color: #FFE57F;
}

.page-button:hover {
  background-color: #FFE57F;
  transform: scale(1.05);
}

.dark .page-button:hover{
  background-color: #FFB74D;
}

.page-button:disabled {
  background-color: #CCC;
  cursor: not-allowed;
}

/* Información de la paginación */
.page-info {
  font-size: 14px;
  color: #333;
  font-family: "Inter", sans-serif;
}

.dark .page-info {
  color: #FFFFFF;
}

/* boton de retorno */

.back-button{
  padding: 10px 18px;
  background-color: #FFB74D;
  color: #000000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  transition: background-color 0.3s, transform 0.2s ease;
}

.dark .back-button{
  background-color: #FFE57F;
}

.back-button:hover {
  background-color: #FFE57F;
  transform: scale(1.05);
}

.dark .back-button:hover{
  background-color: #FFB74D;
}


/* Estilos responsivos */
@media (max-width: 768px) {
  .mis-compras-container {
    padding: 16px;
  }

  h2 {
    font-size: 24px;
  }

  .ticket-item {
    width: 240px;
    padding: 14px;
  }

  .ticket-item p {
    font-size: 13px;
  }

  .qr-code {
    width: 90px;
    height: 90px;
  }

  .page-button {
    padding: 8px 14px;
    font-size: 12px;
  }
}