/* Estilos para la superposición de carga */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #757272; /* Fondo gris claro */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999; /* Asegúrate de que se muestre sobre otros elementos */
  overflow: hidden; /* Evitar que el contenido se desborde */
}

/* Posición de las imágenes en las 4 esquinas */
.loading-overlay .corner-img {
  position: absolute;
  width: 150px;
  height: 150px;
  background-size: contain;  /* Cambiado de 'cover' a 'contain' para evitar el recorte */
  background-position: center;
  background-repeat: no-repeat; /* Para que la imagen no se repita si es más pequeña */
}


.loading-overlay .top-left {
  top: 10px;
  left: 10px;
}

.loading-overlay .top-right {
  top: 10px;
  right: 10px;
}

.loading-overlay .bottom-left {
  bottom: 10px;
  left: 10px;
}

.loading-overlay .bottom-right {
  bottom: 10px;
  right: 10px;
}

/* Estilos para el spinner de carga */
.spinner {
  border: 4px solid rgba(247, 173, 15, 0.3); /* Borde exterior gris */
  border-top: 4px solid #f3f725; /* Borde superior blanco para el spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

/* Animación de rotación */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Estilos para el texto */
.loading-overlay p {
  color: white;
  font-size: 18px;
  margin-top: 10px;
}

:root {
  --bg-light: #FFFFFF;
  --bg-dark: #1D1D1D;
  --primary: #FFE57F;
  --secondary: #FFB74D;
  --accent: #FFD166;
  --text-light: #343a40;
  --text-dark: #f8f9fa;
}

/* Estilos principales */
.show-container {
  padding: 24px;
  max-width: 800px;
  margin-top: 120px;
  background-color: var(--bg-light);
  color: var(--text-light);
  border-radius: 10px;
  transition: background 0.3s;
  margin-left: 330px;
  
}

.dark .show-container {
  background-color: var(--bg-dark);
  color: var(--text-dark);
  text-decoration-color:white;
}

.show-title {
  color: var(--secondary);
  font-family: "Poppins", sans-serif;
  
}

.dark .show-title {
  color: var(--primary);
  font-family: "Poppins", sans-serif;
  
}

.show-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 16px;
}

.zone-info {
  margin-top: 16px;
  padding: 16px;
  background-color: var(--bg-light);
  border-radius: 8px;
  transition: background 0.3s;
}

.dark .zone-info {
  background-color: var(--bg-dark);
}

.zone-title {
  color: var(--secondary);
}

.dark .zone-title {
  color: var(--primary)
}

.purchase-title {
  color: var(--secondary);
  margin-top: 16px;
}

.dark .purchase-title {
  color: var(--primary);
  margin-top: 16px;
}

.purchase-options {
  margin-bottom: 16px;
}

.division-select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
  margin-top: 5px;
}

.buy-button {
  width: 50%;
  padding: 12px 10px;
  cursor: pointer;
  background-color: var(--secondary);
  color: #000000;
  border: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.dark .buy-button {
  width: 50%;
  padding: 12px 10px;
  cursor: pointer;
  background-color: var(--primary);
  color: #000000;
  border: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.buy-button:hover {
  background-color: var(--secondary);
}

/* Responsividad */
@media (max-width: 1024px) {
.show-container {
  padding: 24px;
  max-width: 800px;
  margin-top: 120px;
  background-color: var(--bg-light);
  color: var(--text-light);
  border-radius: 10px;
  transition: background 0.3s;
  margin-left: 4px;
  
}

}