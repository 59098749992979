/* UsersManagement.css */
.users-management {
  padding: 20px;
  z-index: 20;
  margin-top: 5%; /* Ajustado para no solaparse con la navbar */
  margin-right: 200px;
}



/* plaeces.css management */
.places-management {
  margin-left: -200px;
  margin-bottom: 20px;
}

.create-place-form {
  margin-left: 150px;
  margin-bottom: 20px;
}

.botonedit{
  background-color: #f1b11a; /* naranja llamativo */
  color: #000000;
  padding: 10px 11px; /* Ajusta el tamaño del padding para que los botones sean más grandes */
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 23px;
  margin-left: 10px; /* Añade espacio entre los botones */
  position: relative; /* Usamos position relative para mover el botón sin afectar al layout */
  top: -9px; /* Mueve el botón hacia abajo */
  }
  
  
  .botonedit:hover {
    background-color: #bd6009; /* rojizo llamativo */
    color: #000000;
  }
  
  .dark .botonedit {
    color: #000000
  
  }
.boton-barra-adm{
  background-color: #FFFFFF; /* Verde llamativo */
  color: #1D1D1D;
  padding: 16px 16px; /* Ajusta el tamaño del padding para que los botones sean más grandes */
  border-radius: 0px;
  margin-top: 8px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  margin-left: 0px; /* Añade espacio entre los botones */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.dark .boton-barra-adm{
  background-color: #1D1D1D; /* Verde llamativo */
  color: #FFE57F;
}

.boton-barra-adm:hover{
  background-color: #ffd1664d;
  transform:scaleY(1.6);
  border-radius: 3px;
}

.botoncreate {
  background-color: #FFD166; /* Verde llamativo */
  color: #000000;
  padding: 10px 10px; /* Ajusta el tamaño del padding para que los botones sean más grandes */
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 23px;
  margin-left: 10px; /* Añade espacio entre los botones */
}

.dark .botoncreate {
  background-color: #f1b11a; /* Verde llamativo */
  color: #000000;
}

.export-button {
  background-color: #f1b11a; /* Verde llamativo */
  color: #000000;
  padding: 10px 10px; /* Ajusta el tamaño del padding para que los botones sean más grandes */
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 23px;
  margin-left: 10px; /* Añade espacio entre los botones */
  font-weight: bold;
}

.dark .export-button {
  background-color: #f7b317; /* Verde llamativo */
  color: #000000;
 
}

.places-list {
  background-color: #e9e9e9; /* Blanco */
  color: #000000;
  padding: 10px 10px; /* Ajusta el tamaño del padding para que los botones sean más grandes */
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 23px;
  margin-left: 150px; /* Añade espacio entre los botones */
  font-weight: bold;
}

.botonedit-desactivated {
  background-color: #f1b11a; /* Naranja llamativo */
  color: #000000;
  padding: 10px 10px; /* Ajusta el tamaño del padding para que los botones sean más grandes */
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 10px; /* Añade espacio entre los botones */
  position: relative; /* Usamos position relative para mover el botón sin afectar al layout */
  top: -9px; 
}

.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  z-index: 999;
}

.dark .botonedit-desactivated {
  
  color: #000000;
  top: -10px; /* Mueve el botón hacia arriba */
  }


.botonedit-desactivated:hover {
  background-color: #bd6009; /* Rojizo llamativo */
  color: #000000;
}
.search-bar input {
  padding: 10px;
  font-size: 16px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 999;
}

.users-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  z-index: 999;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
}

.users-table th, .users-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
  z-index: 999;
}

.users-table th {
  /* background-color: #007bff; */
  color: rgb(0, 0, 0);
}

.users-table td button {
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #FFD166;
  color: rgb(0, 0, 0);
}

.users-table td button:hover {
  opacity: 0.9;
}

/* Dashboard.css */
.dashboard {
  padding: 20px;
  z-index: 999;
  margin-top: 70px; /* Ajustado para no solaparse con la navbar */
  margin-left: -240px;
}

.dashboard h2 {
  text-align: center;
  margin-bottom: 30px;
  z-index: 999;
}

.metrics-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  z-index: 999;
  margin-left: 15%
}

.metric-card {
  /* background-color: #f8f9fa; */
  background-color: #FFFFFF;
  padding: 20px;
  padding-left: 80px;
  padding-right: 80px;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  z-index: 999;
  
}

.dark .metric-card{
  background-color: #1D1D1D;
}

.metric-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.metric-card p {
  font-size: 24px;
  color: #FFB74D;
  font-weight: bold;
}

.dark .metric-card p {
  color: #FFE57F;
}

.charts-container {
  display: flex;
  justify-content: space-around;
  z-index: 999;
  background-color: #FFFFFF;
  border-radius: 16px;
  margin-left: 18%
}

.dark .charts-container {
  background-color: #1D1D1D;
}

.chart {
  width: 60%;
}

/* EventManager.css */
.events-page {
  padding: 20px;
  z-index: 20;
  margin-top: 125px; /* Ajustado para no solaparse con la navbar */
  margin-left: 20%;

}

.event-manager h2 {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 150px
}

.event-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
  z-index: 999;
  margin-right: 500px;
}

.event-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.event-form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.event-form button:hover {
  background-color: #0056b3;
}

.events-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-right: 100px;
}

.event-card {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
}

.event-card h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.event-card p {
  font-size: 14px;
  margin-bottom: 5px;
}

.event-card button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.event-card button:hover {
  background-color: #218838;
}


/*PLACES */

.events-page {
  padding: 20px;
  z-index: 20;
  margin-top: 5%; /* Ajustado para no solaparse con la navbar */
  margin-right: 200px;

}


/* admin-header */
.admin-header {
  background-color: #FFFFFF;
  /* color: rgb(0, 0, 0); */
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 20%;
  z-index: 1000; /* Asegura que la navbar esté siempre por encima de todo */
  margin-left: -290px ;
}

.dark .admin-header {
  background-color: #1D1D1D;
}

.dark .admin-header .logo h2 {
  color: #FFE57F;
}

.admin-header .logo h2 {
  margin: 0;
  padding-left: 20px;
  z-index: 999;
}

.dark .admin-header .logo h2 {
  color: #FFE57F;
}

.admin-header nav ul {
  list-style-type: none;
  margin: 0;
  padding-right: 20px;
  display: flex;
  z-index: 999;
}

.admin-header nav ul li {
  margin-right: 20px;
  z-index: 999;
}

.admin-header nav ul li a,
.admin-header nav ul li button {
  color: rgb(0,0,0);
  text-decoration: none;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 999;
}

.admin-header nav ul li button:hover,
.admin-header nav ul li a:hover {
  text-decoration: underline;
}

/* admin-panel */
.admin-panel {
  display: flex;
  height: 100vh;
  /* background-color: #f4f4f4; */
  padding-top: 70px; /* Para que no se solape con la navbar */
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: #d5d7d8;
  color: rgb(0, 0, 0);
  padding: 20px;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 2px 0 5px rgb(255, 255, 255);
  z-index: 10;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin: 20px 0;
}

.sidebar li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  display: block;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.sidebar li a:hover {
  background-color: #34495e;
}

/* Main content */
.main-content {
  margin-left: 250px; /* Asegura que el contenido no se solape con la sidebar */
  padding: 20px;
  width: calc(100% - 250px);
  transition: margin-left 0.3s;
  margin-top: 20px;
  position: relative;
  z-index: 5;
}

/* Header */
.header {
  background-color: #3498db;
  color: white;
  padding: 15px;
  text-align: center;
  font-size: 22px;
}

.header h1 {
  margin: 0;
  font-size: 24px;
}

/* Botones y elementos dentro de las rutas */
.button-admin {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button-admin:hover {
  background-color: #2980b9;
}

/* Estilo para las tablas en las secciones de gestión */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.dark th{
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  color:rgb(255, 253, 253); 
  background: #727272;
  text-align: center;
  
}


td {
  background-color: #ecf0f1;
  color: black;
}

.dark td {
  background-color: #727272;
  color: rgb(255, 255, 255);
  text-align: center;
}

/* Estilo para el contenido de cada página */
.page-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(250, 141, 141, 0.1);
}

/* Sección de error o carga */
.loading, .error {
  text-align: center;
  font-size: 18px;
  color: #e74c3c;
  margin-top: 20px;
}

/* Responsive: Ajustar en pantallas más pequeñas */
@media (max-width: 768px) {
  .admin-panel {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .main-content {
    margin-left: 0;
    width: 100%;
    margin-top: 230px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th, td {
  border: 1px solid #ddd; /* Agrega un borde a las celdas */
  padding: 10px;
  text-align: center;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
  border-bottom: 2px solid #ccc; /* Línea más gruesa debajo de los encabezados */
  text-align: center;
}

tr:nth-child(even) {
  background-color: #ccc; /* Alterna colores en filas */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  padding: 8px 12px;
  margin: 0 5px;
  background-color: #FFE57F;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}




.pagination-button:disabled {
  background-color: #FFD166;
  cursor: not-allowed;
}

.dark .pagination-button:disabled {
  background-color: #f1b11a;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: #db9c28;
}


@media (max-width: 600px) {
  .admin-header {
    background-color: #FFFFFF;
    /* color: rgb(0, 0, 0); */
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 14%;
    z-index: 1000; /* Asegura que la navbar esté siempre por encima de todo */
    margin-left: -30px ;
  }
}

