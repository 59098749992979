/* Variables de color para modo claro y oscuro */
:root {
  --color-dominante-light: #FFFFFF;
  --color-dominante-dark: #1d1d1d;
  --color-principal: #FFE57F;
  --color-secundario: #FFB74D;
  --color-intermedio: #FFD166;
  --color-texto-light: #333;
  --color-texto-dark: #f9f9f9;
  --borde-color-light: #ddd;
  --borde-color-dark: #444;
}

/* Estilos generales del contenedor */
.create-show-form {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: var(--color-dominante-light);
  border: 1px solid var(--borde-color-light);
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  transition: background-color 0.3s ease;
  margin-top: 120px;
}

body.dark .create-show-form {
  background-color: var(--color-dominante-dark);
  border: 1px solid var(--borde-color-dark);
}

/* Título */
.create-show-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: var(--color-texto-light);
  font-size: 1.8rem;
}

body.dark .create-show-form h2 {
  color: var(--color-texto-dark);
}

/* Estilos de los labels */
.create-show-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: var(--color-texto-light);
}

body.dark .create-show-form label {
  color: var(--color-texto-dark);
}

/* Inputs de texto y textarea */
.create-show-form input[type="text"],
.create-show-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--borde-color-light);
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border 0.3s ease;
}

body.dark .create-show-form input[type="text"],
body.dark .create-show-form textarea {
  border: 1px solid var(--borde-color-dark);
  color: var(--color-texto-light);
}

/* Botón de envío */
.create-show-boton {
  display: block;
  width: 40%;
  padding: 10px;
  margin: 0 auto;
  background-color: var(--color-secundario);
  color: #000;
  font-size: 1.2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-show-boton:hover {
  background-color: var(--color-intermedio);
}

/* Modo oscuro para botón */
body.dark .create-show-boton {
  background-color: var(--color-principal);
  color: #000;
}

/* Contenedor de input con botón */
.input-with-button {
  position: relative;
  display: flex;
  align-items: center;
}

.input-with-button input {
  flex: 1;
  padding-right: 50px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid var(--borde-color-light);
  border-radius: 4px;
}

.input-with-button .add-link {
  position: absolute;
  right: 0px;
  color: #000000;
  background-color: var(--color-principal);
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.input-with-button .add-link:hover {
  background-color:  var(--color-intermedio);
}


/* Estilos responsivos */
@media (max-width: 768px) {
  .create-show-form {
    padding: 15px;
  }

  .create-show-form h2 {
    font-size: 1.5rem;
  }

  .create-show-boton {
    font-size: 1rem;
  }
}

/* Géneros seleccionados */
.selected-genres-container {
  margin-top: 10px;
}

.selected-genres-box {
  border: 1px solid var(--borde-color-light);
  padding: 10px;
  background-color: var(--color-dominante-light);
  border-radius: 5px;
  max-height: 100px;
  overflow-y: auto;
}

body.dark .selected-genres-box {
  background-color: var(--color-dominante-dark);
  border: 1px solid var(--borde-color-dark);
}

.genre-tag {
  display: inline-block;
  background-color: var(--color-principal);
  padding: 5px 10px;
  margin: 5px;
  border-radius: 8px;
  font-size: 14px;
  color: #000;
}

/* Botón de eliminación de géneros */
.remove-genre-button {
  margin-left: 3px;
  background-color: transparent;
  border: none;
  color: #ff4d4d;
  cursor: pointer;
  font-size: 12px;
}

.remove-genre-button:hover {
  color: #ff0000;
}
