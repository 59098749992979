/* Contenido del modal */
.modal-contenido {
    background:#FFFFFF;
    padding: 60px;
    border-radius: 8px;
    width: 100%;
    max-width: 450px; /* Limita el ancho máximo del contenido */
    z-index: 1001;
    max-height: 450px;
    color: black;
    overflow: auto; /* Añade desplazamiento vertical cuando el contenido exceda la altura máxima */
  
  }


  .dark .modal-contenido {
    background:#1D1D1D;
    padding: 60px;
    border-radius: 8px;
    width: 100%;
    max-width: 450px; /* Limita el ancho máximo del contenido */
    z-index: 1001;
    max-height: 800px;
    color:white;
    max-height: 450px;
  }

  .event-image-confirm {
    width: 250px;
    height: 160px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 10px;
    transition: transform 0.3s ease-in-out;
  }

  .close-btn {
    padding: 10px 20px;
    font-size: 16px;
    background: #e09e0e;
    color: rgb(15, 15, 15);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 80px;
  }
  
  .close-btn:hover {
    background: #FFD166; /* Color más oscuro cuando el mouse pasa sobre el botón */
  }

  /* Botones */
.select-seats-btns {
    padding: 12px 24px; /* Aumenta el tamaño del botón */
    margin-top: 10px;
    width: 100%; /* Ajusta el botón para ocupar el 100% del contenedor */
    font-size: 16px;
    background: #FFB74D;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .dark .select-seats-btns {
    padding: 12px 24px; /* Aumenta el tamaño del botón */
    margin-top: 10px;
    width: 100%; /* Ajusta el botón para ocupar el 100% del contenedor */
    font-size: 16px;
    background: #FFE57F;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .dark .select-seats-btns:hover {
    padding: 12px 24px; /* Aumenta el tamaño del botón */
    margin-top: 10px;
    width: 100%; /* Ajusta el botón para ocupar el 100% del contenedor */
    font-size: 16px;
    background: #FFD166;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  /* Responsividad para pantallas de móvil (max-width: 511px) */
@media (max-width: 511px) {
  .modal-contenido {
    max-width: 90%; /* Reduces el ancho del modal */
    padding: 20px; /* Menos padding para ahorrar espacio */
    max-height: 90vh; /* Limita la altura a un 90% de la pantalla */
    overflow-y: auto; /* Permite desplazarse si el contenido excede la altura */
  }

  .dark .modal-contenido {
    max-width: 90%;
    padding: 20px;
    max-height: 90vh;
    overflow-y: auto;
  }

  .event-image-confirm {
    width: 100%; /* Hace que la imagen ocupe todo el ancho disponible */
    height: auto; /* Ajusta la altura proporcionalmente */
    border-radius: 12px;
    margin-bottom: 10px;
  }

  .close-btn {
    padding: 8px 16px; /* Reduces el tamaño del botón */
    font-size: 14px;
    background: #e09e0e;
    color: rgb(15, 15, 15);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px; /* Espacio más pequeño */
  }

  .close-btn:hover {
    background: #FFD166; /* Color más oscuro cuando el mouse pasa sobre el botón */
  }

  /* Botones */
  .select-seats-btns {
    padding: 12px 24px;
    margin-top: 10px;
    width: 100%;
    font-size: 16px;
    background: #FFB74D;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .dark .select-seats-btns {
    padding: 12px 24px;
    margin-top: 10px;
    width: 100%;
    font-size: 16px;
    background: #FFE57F;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .dark .select-seats-btns:hover {
    background: #FFD166;
    color: rgb(0, 0, 0);
  }
}

/* Responsividad para pantallas de tablet (max-width: 860px) */
@media (max-width: 860px) {
  .modal-contenido {
    max-width: 80%; /* Modal ocupa el 80% del ancho */
    padding: 30px;
    max-height: 80vh; /* Limita la altura a un 80% de la pantalla */
  }

  .dark .modal-contenido {
    max-width: 80%;
    padding: 30px;
    max-height: 80vh;
  }

  .event-image-confirm {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .close-btn {
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 20px;
  }

  .select-seats-btns {
    padding: 12px 24px;
    margin-top: 10px;
    width: 100%;
    font-size: 16px;
  }

  .dark .select-seats-btns {
    padding: 12px 24px;
    margin-top: 10px;
    width: 100%;
    font-size: 16px;
  }

  .dark .select-seats-btns:hover {
    background: #FFD166;
    color: rgb(0, 0, 0);
  }
}