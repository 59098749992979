/* Estilos generales para el contenedor de gestión de lugares */
.places-management {
  font-family: Arial, sans-serif;
  max-width: 800px; /* Reducir el tamaño máximo del contenedor */
  margin: 3% auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos dentro del contenedor */
  justify-content: center;
  height: 100vh; /* Ocupa toda la altura de la pantalla */
  margin-right: 250px;
}

.places-management h2 {
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}

.name-places{
  color: orange;
}

/* Estilos para el formulario de agregar lugar */
.places-management .add-place-form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Reducir el espacio entre los elementos del formulario */
  background-color: #FFB74D;
  color: #000000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
  max-width: 500px; /* Limita el ancho del formulario */
}

.places-management .add-place-form:hover {
  background-color: #FFD166;
  }

.places-management .add-place-form label {
  font-size: 1rem;
  margin-bottom: 8px;
  color: #333;
}

.places-management .add-place-form input,
.places-management .add-place-form select {
  width: 100%; /* Hace que los inputs ocupen todo el ancho del formulario */
  padding: 8px; /* Reducir el tamaño del padding */
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.875rem; /* Reducir el tamaño de la fuente de los inputs */
}

.places-management .add-place-form input:focus,
.places-management .add-place-form select:focus {
  border-color: #4a90e2;
  outline: none;
}

.places-management .add-place-form button {
  background-color: #4a90e2;
  color: white;
  padding: 8px 15px; /* Reducir el tamaño del botón */
  font-size: 0.875rem; /* Reducir el tamaño de la fuente */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: center; /* Centra el botón dentro del formulario */
}

.places-management .add-place-form button:hover {
  background-color: #357ab7;
}

.places-management .add-place-form .error-message {
  color: #FFB74D;
  margin-bottom: 10px;
  font-size: 0.875rem;
}

/* Estilos para la lista de lugares */
.places-management .places-list {
  margin-top: 30px;
}

.places-management .places-list h3 {
  font-size: 1.5rem;
  color: #000000;
  margin-bottom: 15px;
  text-align: center;
}

.places-management .places-list ul {
  list-style-type: none;
  padding: 0;
}

.places-management .places-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 100px 85pxx rgba(0, 0, 0, 0.1);
}

.places-management .places-list li span {
  color: #333;
  font-size: 1rem;
}

.places-management .botonedit-desactivated {
  background-color: #FFE57F;
  color: rgb(0, 0, 0);
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.3s;
  margin-left:auto;
  margin-top: auto;
  margin: 15px;
}

.places-management .botonedit-desactivated:hover {
  background-color: #FFD166;
}
/* Paginación */
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pagination button {
  padding: 10px 20px;
  border: none;
  background-color: #FFB74D;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #FFD166;
}

.dark .pagination button {
  padding: 10px 20px;
  border: none;
  background-color: #FFE57F;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  cursor: pointer;
}

.dark .pagination button:hover {
  background-color: #FFD166;
}

/* Estilo para los lugares en formato de fila */
.places-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Espacio entre los lugares */
  justify-content: center;
}

.place-item {
  background-color: #ffffff;
  padding: 30px 50px;
  border-radius: 8px;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
  width: calc(22% - 10px); /* 3 lugares por fila */
  text-align: center;
 
}
.dark .place-item {
  background-color: #1D1D1D;
  padding: 30px 50px;
  border-radius: 8px;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
  width: calc(22% - 10px); /* 3 lugares por fila */
  text-align: center;
 
}

.place-item span {
  display: center;
  margin-bottom: -5px;
  color: #000000;
  margin-left:auto;
  margin: 2px
}

.dark .place-item span {
  display: center;
  margin-bottom: -5px;
  color: #ffffff;
  margin-left:auto;
  margin: 2px
}
